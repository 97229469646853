import { useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";

export default function LoginForm({changeForm}: {changeForm: (formName: string) => void}) {
    const login = useSignal("")
    const password = useSignal("")
    const error = useSignal("")
    const rememberMe = useSignal(false);

    // Загружаем сохраненные данные при монтировании
    useEffect(() => {
        const savedLogin = localStorage.getItem("savedLogin");
        const savedPassword = localStorage.getItem("savedPassword");
        if (savedLogin && savedPassword) {
            login.value = savedLogin;
            password.value = savedPassword;
            rememberMe.value = true;
        }
    }, []);

    async function sendLoginRequest() {
        const result = await fetch("/api/login", {
            method: "POST",
            body: JSON.stringify({
                login: login.value,
                password: password.value
            })
        })

        const data = await result.json()
        console.log(data);
        
        if (data.success) {
            if (rememberMe.value) {
                localStorage.setItem("savedLogin", login.value);
                localStorage.setItem("savedPassword", password.value);
            } else {
                localStorage.removeItem("savedLogin");
                localStorage.removeItem("savedPassword");
            }
            window.location.replace("/")
        } else {
            error.value = data.reason
        }
    }

    return (
        <div class="d-flex flex-column mx-auto my-3 register w-[452px]">
            <h3 class="text-center mb-0 text-2xl font-semibold">Вход</h3>
            <div class="d-flex flex-column h-[500px] shadow gap-[22px] mt-[27px] px-[12px]">
                <p class="text-center fw-medium fs-6 mt-3" style="color: #4a4a4a">Введите e-mail и пароль</p> 
                {error.value && <div class="alert alert-danger text-center mx-auto rounded-0 mb-0">{error.value}</div>}

                <input type="email" name="username" required class="enter-form" placeholder="E-mail" value={login.value} onInput={e => login.value = e.currentTarget.value} />
                <input type="password" name="password" required class="enter-form" placeholder="Пароль" value={password.value} onInput={e => password.value = e.currentTarget.value} />
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row align-items-start justify-content-between w-100 gap-3">
                        <label class="square-label" for="remember" style="font-size: 12px">
                            <input
                                class="square-checkbox"
                                type="checkbox"
                                id="remember"
                                checked={rememberMe.value}
                                onChange={() => (rememberMe.value = !rememberMe.value)}
                            />
                            <span class="square-checkbox-box"></span>
                            Запомнить данные для входа
                        </label>
                        <button onClick={() => sendLoginRequest()} class="btn-black">Войти</button>
                    </div>
                    <div class="d-flex flex-column">
                        <button onClick={() => changeForm("register")} class="text-decoration-none text-start" style="font-weight: regular; font-size: 12px; color: #4f4f4f">Нет аккаунта? <span style="text-decoration: underline;">Зарегистрироваться</span></button>
                        <button onClick={() => window.location.replace("/forgot")} class="mt-1 text-decoration-none text-start" style="font-weight: regular; font-size: 12px; color: #4f4f4f"><span style="text-decoration: underline;">Забыли пароль?</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}